<template>
  <MobileHeader
    :mobileHeaderTitle="'FAVOURITES'"
    :tabs="$route.name"
  ></MobileHeader>

  <div class="container has-space has-header">
    <h1 class="fav-title" v-if="!isMobile">{{ $t("FAVOURITES") }}</h1>
    <div class="display-flex fav-nav__wrapper">
      <template v-for="(o, index) in tabs" :key="index">
        <router-link :to="{ name: o.pathName }" class="sub-nav__child">
          <span class="sub-nav__child-text">{{ $t(o.name) }}</span>
          <div class="sub-nav__child-indicator"></div>
        </router-link>
      </template>
    </div>
    <div class="fav-content__wrapper display-flex flex-col">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import MobileHeader from "@/components/mobile/Header.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    MobileHeader,
  },
  data() {
    return {
      tabs: [],
      footballTabs: [
        {
          name: "MATCHES",
          pathName: "footballFavMatches",
          selected: true,
        },
        {
          name: "TEAMS",
          pathName: "footballFavTeams",
          selected: false,
        },
        {
          name: "COMPETITIONS",
          pathName: "footballFavCompetitions",
          selected: false,
        },
      ],
      basketballTabs: [
        {
          name: "MATCHES",
          pathName: "basketballFavMatches",
          selected: true,
        },
        {
          name: "TEAMS",
          pathName: "basketballFavTeams",
          selected: false,
        },
        {
          name: "COMPETITIONS",
          pathName: "basketballFavCompetitions",
          selected: false,
        },
      ],
      currentTab: [],
    };
  },
  computed: {
    ...mapGetters([
      "isMobile", 
      "isLogin",
      "currentLocale",
      "currentSportType",
    ]),
  },
  watch: {
    isLogin: {
        deep: true,
        handler(newVal, oldVal) {
            if (!newVal) {
              this.$router.push({ name: 'home'})
            }
        },
    },
    currentSportType: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal === "football")
          this.tabs = this.footballTabs;
        else if (newVal === "basketball")
          this.tabs = this.basketballTabs;
      },
    },
  },
  mounted() {
    this.commonVerifyLogin();
    if (!this.isLogin) {
      location.href = `/${this.currentLocale}`
    }
  },
  methods: {
    ...mapActions([
      "commonVerifyLogin",
    ]),
  },
};
</script>

<style>
.fav-title {
  font-weight: 700;
  font-size: 34px;
  line-height: 40px;
  margin-bottom: 2rem;
}
.fav-subtitle {
  font-size: 0.875rem;
  line-height: 1.3rem;
  color: #6F8C9B;
  margin: 1rem auto;
  text-align: center;
  /* display: inline-block;
  vertical-align: middle; */
  width: 14rem;
}
.fav-subtitle-1line{
  width: 100%;
}
.fav-nav__wrapper {
  margin: 1rem auto;
  width: 37.5rem;
}
.fav-content__wrapper {
  margin: 0 auto;
  width: 37.5rem;
  min-height: 31.25rem;
  align-items: center;
}
.fav-search {
  width: 100%;
}
.fav-search input {
  cursor: pointer;
}
.fav-card__container {
  width: 100%;
  min-height: 29.25rem;
  gap: 0.5rem;
  margin: 0.5rem 0;
}
.fav-search-icon {
  margin: 0 0.25rem;
  display: inline-block;
  vertical-align: middle;
}
.fav-icon__sample{
    position: relative;
    top: 3px;
    margin-right: 4px;
}
@media (max-width: 768px) {
  .fav-nav__wrapper {
    width: 100%;
  }
  .fav-content__wrapper {
    width: 100%;
  }
}
</style>
